<template>
  <div class="body container">
    <Breadcrumb>
      <template slot="lastPage">
        {{ pageTitle }}
      </template>
    </Breadcrumb>
    <h1 class="text-center mt-5 mb-3">
      {{ pageTitle }}
    </h1>
    <el-row class="rowsGap" :gutter="20">
      <el-col :span="3">
        <span>{{ $t("bill.form.billName") }}</span>
      </el-col>
      <el-col :span="21">
        <el-input
          :value="$store.getters['bill/billTitle']"
          @input="
            value => {
              $store.dispatch('bill/setBillTitle', value);
            }
          "
        />
      </el-col>
    </el-row>
    <el-row class="rowsGap" :gutter="20">
      <el-col :span="3">繳費期限：</el-col>
      <el-col :span="21">
        <el-date-picker
          :value="$store.getters['bill/billReceivableDate']"
          @input="
            value => {
              $store.commit('bill/setBillReceivableDate', value);
            }
          "
          type="date"
          value-format="yyyy-MM-dd"
          style="width: 100%;"
        />
      </el-col>
    </el-row>
    <el-row class="rowsGap" :gutter="20">
      <el-col :span="3">{{ $t("bill.form.currency") }}</el-col>
      <el-col :span="6">
        <CurrencySelector
          :value="$store.getters['bill/billCurrency']"
          @change="changeBillCurrency"
        />
      </el-col>
    </el-row>
    <el-row class="rowsGap" :gutter="20">
      <el-col :span="3">Notes</el-col>
      <el-col :span="21">
        <el-input
          style="width: 100%;"
          type="textarea"
          :rows="2"
          :value="$store.getters['bill/billNotes']"
          @input="
            value => {
              $store.dispatch('bill/setBillNotes', value);
            }
          "
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="rowsGap" :gutter="20">
      <el-col :span="3">{{ $t("bill.form.parent") }}</el-col>
      <el-col :span="6" v-if="!$store.getters['bill/billParent'].name">
        <el-select
          style="width: 100%"
          :value="$store.getters['bill/billParent'].id"
          @change="selectedParent"
          :placeholder="$t('bill.form.searchParent')"
          clearable
          filterable
          remote
          :remote-method="fetchParents"
        >
          <el-option
            v-for="student in searchStudents"
            :key="`${student.parent_user_id}${student.student_user_id}`"
            :label="
              `${userMethods.displayName(
                student.parent_user_first_name,
                student.parent_user_last_name
              )}/${userMethods.displayName(
                student.student_user_first_name,
                student.student_user_last_name
              )}`
            "
            :value="student"
          />
        </el-select>
      </el-col>
      <el-col v-else :span="6">
        <router-link
          :to="{
            name: 'ProfileForAdmin',
            params: { id: $store.getters['bill/billParent'].id }
          }"
        >
          {{ $store.getters["bill/billParent"].name }}
        </router-link>
        <span @click="removeParent">
          <i class="fas fa-edit action-icon" />
        </span>
      </el-col>
    </el-row>
    <div v-if="$store.getters['bill/billParent'].name">
      <el-row class="rowsGap" :gutter="20">
        <el-col :span="3">
          {{ $t("bill.form.student") }}
        </el-col>
        <el-col :span="16">
          <StudentForm
            :key="student.id"
            :student="student"
            :students="studentsByParent"
            @setStudent="
              newStudent => {
                $store.dispatch('bill/setTempStudent', { index, newStudent });
              }
            "
            @checkTempStudent="
              () => {
                checkTempStudent(index);
              }
            "
            @editTempStudent="
              () => {
                $store.dispatch('bill/setTempStudent', {
                  index,
                  newStudent: { edit: true }
                });
              }
            "
            @removeStudent="removeStudent(student, index)"
            v-for="(student, index) in $store.getters['bill/tempStudents']"
          />
        </el-col>
      </el-row>
      <el-row style="margin-top: -20px" :gutter="20">
        <el-col style="opacity: 0" :span="3">Fill block</el-col>
        <el-col :span="6">
          <el-button
            type="primary"
            size="mini"
            v-if="studentsByParent.length > 1"
            @click="$store.dispatch('bill/addTempStudent')"
          >
            {{ $t("bill.form.addStudent") }}
          </el-button>
          <el-button
            type="primary"
            size="mini"
            v-if="studentsByParent.length > 1"
            @click="checkAllTempStudent"
          >
            增加所有的學生
          </el-button>
        </el-col>
      </el-row>
      <hr class="separate-line" />
      <el-row class="rowsGap">
        <OrderItems
          :editor="true"
          :isBillMode="true"
          :students="studentsByParent"
          :orderItems="$store.getters['bill/orderItems']"
          @updatePriceOfEnrollment="updatePriceOfEnrollment"
          @switchOrderItemSelectType="
            switchStatus => {
              switchSelectType('orderItem', switchStatus);
            }
          "
          @setBillTotalPrice="
            billTotalPrice => {
              $store.dispatch('bill/setBillTotalPrice', billTotalPrice);
            }
          "
          @addOtherItem="addOtherItem"
          @setOtherItem="setOtherItem"
          @removeOtherOrderItem="removeOtherOrderItem"
        />
      </el-row>
      <el-row
        class="rowsGap"
        v-if="$store.getters['bill/lessonLogs'].length > 0 && setLessonLogs($store.getters['bill/lessonLogs']) && setLessonLogs($store.getters['bill/lessonLogs']).length > 0"
      >
        <hr class="separate-line" />
        <LessonLogs
          :lessonLogs="setLessonLogs($store.getters['bill/lessonLogs'])"
          :billCurrency="$store.getters['bill/billCurrency']"
          @switchLessonLogSelectType="
            switchStatus => {
              switchSelectType('lessonLog', switchStatus);
            }
          "
        />
      </el-row>
      <template v-if="isExistSelectedOrderItem">
        <hr class="separate-line" />
        <el-row class="rowsGap">
          <h2>{{ $t("bill.form.paymentType") }}</h2>
          <el-row class="rowsGap" type="flex" align="middle">
            <el-col :span="3">{{
              $t("bill.form.pleaseChoosePaymentType")
            }}</el-col>
            <el-col :span="21">
              <el-select
                style="width: 100%"
                :value="$store.getters['bill/billPaymentIndex']"
                @change="changePaymentIndex"
              >
                <el-option
                  v-for="tutoringPayment in tutoringPayments"
                  :key="tutoringPayment.index"
                  :label="addNoteToPaymentTitle(tutoringPayment.title)"
                  :value="tutoringPayment.index"
                />
              </el-select>
            </el-col>
          </el-row>
          <el-row class="rowsGap" type="flex" align="middle">
            <el-col :span="3">{{ $t("bill.form.payment") }}</el-col>
            <el-col :span="21">
              <div v-if="isPayByBalance">
                <el-row>
                  {{ $t("bill.form.amount") }}
                  {{
                    addCurrencySymbol(
                      $store.getters["bill/billTotalPrice"],
                      $store.getters["bill/billCurrency"]
                    )
                  }}
                </el-row>
                <el-row>
                  {{ $t("bill.form.balance") }}
                  {{ $store.getters["bill/paidBillTotalByBalanceLeft"] }}
                </el-row>
              </div>
              <div v-if="isPayByOther">
                <el-input
                  type="textarea"
                  :value="$store.getters['bill/paymentInfoRemark']"
                  @input="
                    paymentInfoRemark =>
                      $store.commit(
                        'bill/setPaymentInfoRemark',
                        paymentInfoRemark
                      )
                  "
                />
              </div>
              <div v-else class="paymentInfo">
                <div v-if="selectedPayment.account_name">
                  {{ $t("bill.accountName") }}{{ selectedPayment.account_name }}
                </div>
                <div v-if="selectedPayment.bank">
                  {{ $t("bill.bank") }}{{ selectedPayment.bank }}
                </div>
                <div v-if="selectedPayment.account_number">
                  <span v-if="isMobilePayment">{{ $t("bill.account") }}</span>
                  <span v-else>{{ $t("bill.accountNumber") }}</span
                  >{{ selectedPayment.account_number }}
                </div>
                <div v-if="selectedPayment.other_info">
                  <!-- {{ selectedPayment.other_info }} -->
                  <el-image
                    class="mt-2"
                    style="width: 100px; "
                    :src="selectedPayment.other_info"
                    :preview-src-list="[selectedPayment.other_info]"
                  >
                  </el-image>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-row>
        <el-row
          v-if="!isPayByBalance"
          class="rowsGap"
          type="flex"
          align="middle"
          justify="center"
        >
          <span>{{ $t("bill.form.didTheParentsPay") }}</span>
          <el-button
            :type="nextStep === 'sendMail' ? 'primary' : ''"
            @click="enterToNextStep('sendMail')"
          >
            {{ $t("bill.form.noSendToParents") }}
          </el-button>
          <el-button
            :type="nextStep === 'markPaid' ? 'primary' : ''"
            @click="enterToNextStep('markPaid')"
          >
            {{ $t("bill.form.yseMarkPaid") }}
          </el-button>
        </el-row>
        <el-row class="rowsGap" type="flex" justify="center">
          <el-col :span="24">
            <SendMail
              v-if="nextStep === 'sendMail'"
              :students="$store.getters['bill/students']"
              :tutoringPayments="tutoringPayments"
              :parentId="$store.getters['bill/billParent'].id"
            />
            <MarkPaid v-if="nextStep === 'markPaid'" />
          </el-col>
        </el-row>
      </template>
    </div>
  </div>
</template>

<script>
import { user } from "@ivy-way/material";
import moment from "moment";
import moneyMixin from "@/mixins/money";
import userApi from "@/apis/user";
import billApi from "@/apis/bill";
import enrollmentApi from "@/apis/enrollment";
import Breadcrumb from "@/components/Breadcrumb";
import { CurrencySelector } from "@/components/selector";
import StudentForm from "@/components/bill/StudentForm.vue";
import OrderItems from "@/components/bill/OrderItems.vue";
import LessonLogs from "@/components/bill/LessonLogs.vue";
import SendMail from "@/views/bill/SendMail.vue";
import MarkPaid from "@/views/bill/MarkPaid.vue";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.createBill")} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    StudentForm,
    CurrencySelector,
    OrderItems,
    LessonLogs,
    SendMail,
    MarkPaid
  },
  data() {
    return {
      nextStep: null,
      searchStudents: [],
      studentsByParent: [],
      tutoringPayments: [],
      payment: 1,
      lessonsLogs: []
    };
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.createBill");
    },
    isExistSelectedOrderItem() {
      return this.$store.getters["bill/orderItems"].some(
        orderItem => orderItem.selectType !== "unSelected"
      );
    },
    selectedPayment() {
      return (
        this.tutoringPayments.find(
          payment =>
            payment.index === this.$store.getters["bill/billPaymentIndex"]
        ) || {}
      );
    },
    isMobilePayment() {
      const mobilePaymentIndex = [5, 6, 7, 8];
      return mobilePaymentIndex.includes(
        this.$store.getters["bill/billPaymentIndex"]
      );
    },
    isPayByBalance() {
      return this.$store.getters["bill/billPaymentIndex"] === 10;
    },
    isPayByOther() {
      return this.$store.getters["bill/billPaymentIndex"] === 11;
    },
    isBalanceEnough() {
      const { balance, balanceCurrency } = this.$store.getters[
        "bill/billParent"
      ];
      const balanceByBillCurrency = this.convertPriceByCurrency(
        balance,
        balanceCurrency,
        this.$store.getters["bill/billCurrency"]
      );
      return (
        balanceByBillCurrency >= this.$store.getters["bill/billTotalPrice"]
      );
    },
    userMethods() {
      return user;
    }
  },
  mixins: [moneyMixin],
  watch: {
    "$store.state.bill.billCurrency": function() {
      this.updateBalanceInformation();
    },
    "$store.state.bill.billParent": function() {
      this.updateBalanceInformation();
    },
    "$store.state.bill.billTotalPrice": function() {
      if (this.isBalanceEnough) {
        this.$store.dispatch("bill/setBillPaymentIndex", 10);
        this.nextStep = "sendMail";
      } else {
        this.$store.dispatch("bill/setBillPaymentIndex", 1);
        this.nextStep = null;
      }
    }
  },
  async created() {
    await this.initialExchangeRate();
    this.tutoringPayments = await billApi.getTutoringPayments();

    await this.$store.dispatch("bill/initializeBill");

    switch (this.$route.name) {
      case "createBillWithEnrollment": {
        const enrollment = await this.$store.dispatch(
          "bill/generateBillFromEnroll",
          this.$route.params.id
        );
        await this.selectedStudent(enrollment.student, true);
        this.$store.dispatch("bill/setTempStudents", [enrollment.student]);
        break;
      }
      case "createBillWithLessonLog": {
        const lessonLog = await this.$store.dispatch(
          "bill/generateBillFromLessonLog",
          this.$route.params.classId
        );
        await this.selectedStudent(lessonLog.student, true);
        this.$store.dispatch("bill/setTempStudents", [lessonLog.student]);
        const standardLessonLog = this.$store.getters["bill/lessonLogs"].find(
          lessonLog => lessonLog.id === Number(this.$route.params.lessonLogId)
        );
        const defaultSelectedLessonLogs = [
          standardLessonLog,
          ...this.$store.getters["bill/lessonLogs"].filter(lessonLog => {
            return moment(lessonLog.date).isBefore(
              moment(standardLessonLog.date)
            );
          })
        ];
        const promiseSelectedLessonLogs = defaultSelectedLessonLogs.map(
          async lessonLog =>
            await this.switchSelectType("lessonLog", {
              enrolledSessionId: this.$route.params.enrolledId,
              lessonLogId: String(lessonLog.id),
              selectType: "unSelected"
            })
        );

        Promise.all(promiseSelectedLessonLogs);
        break;
      }
      case "createBillWithStudent": {
        const { student } = await this.$store.dispatch(
          "bill/setBillFromStudent",
          this.$route.params.studentId
        );
        await this.$store.commit(
          "bill/setBillReceivableDate",
          moment()
            .add(10, "days")
            .format("yyyy-MM-DD")
        );
        const { children_users } = await billApi.getUnpaidChildrens(
          this.$store.getters["bill/billParent"].id
        );
        const formattedChildrens = children_users.map(children => ({
          ...children,
          user_id: children.id,
          name: this.userMethods.displayName(
            children.first_name,
            children.last_name
          )
        }));
        let selectedStudentIdMap = {};
        let selectedStudentPromises = [];
        [student, ...formattedChildrens].forEach(student => {
          if (!selectedStudentIdMap[student.user_id]) {
            selectedStudentIdMap[student.user_id] = student;
            selectedStudentPromises.push(this.selectedStudent(student, true));
          }
        });
        this.$store.dispatch(
          "bill/setTempStudents",
          Object.keys(selectedStudentIdMap).map(id => selectedStudentIdMap[id])
        );
        await Promise.all(selectedStudentPromises);
        break;
      }
      case "createBillWithOtherBill": {
        const { students, orderItems, lessonLogs } = await this.$store.dispatch(
          "bill/setBillFromOtherBill",
          this.$route.params.billId
        );
        const selectedStudents = [];
        students.forEach(student => {
          selectedStudents.push(this.selectedStudent(student, true));
          this.$store.dispatch("bill/setTempStudents", [student]);
        });
        await Promise.all(selectedStudents);
        const promiseSelectedLessonLogs = lessonLogs.map(
          async lessonLog =>
            await this.switchSelectType("lessonLog", {
              enrolledSessionId: lessonLog.enrolled_session_id,
              lessonLogId: String(lessonLog.id),
              selectType: "unSelected"
            })
        );

        const promiseSelectedOrderItems = orderItems
          .filter(({ buyable_id }) =>
            lessonLogs.some(
              ({ enrolled_session_id }) => enrolled_session_id === buyable_id
            )
          )
          .map(
            async orderItem =>
              await this.switchSelectType("orderItem", {
                orderItemId: String(orderItem.buyable_id),
                selectType: "unSelected"
              })
          );
        Promise.all(promiseSelectedOrderItems);
        Promise.all(promiseSelectedLessonLogs);
        break;
      }
    }
    const isCreateBySpecificMethod = routeName =>
      [
        "createBillWithEnrollment",
        "createBillWithLessonLog",
        "createBillWithStudent",
        "createBillWithOtherBill"
      ].includes(routeName);
    if (isCreateBySpecificMethod(this.$route.name)) {
      this.selectedParent(
        {
          parent_user_id: this.$store.getters["bill/billParent"].id
        },
        true
      );
    }
    this.changeBillCurrency({
      id: this.$store.getters["bill/billParent"].defaultCurrency
    });

    this.setDefaultTitle();
  },
  methods: {
    setLessonLogs(lessonLogs) {
      lessonLogs = lessonLogs.filter(order => {
        return order.fee > 0;
      });
      return lessonLogs;
    },
    addNoteToPaymentTitle(paymentTitle) {
      if (paymentTitle === "台幣轉帳帳號-1") {
        return `${paymentTitle}（家教跟顧問學費用）`;
      } else if (paymentTitle === "台幣轉帳帳號-2") {
        return `${paymentTitle}（台灣團體班學費）`;
      } else {
        return paymentTitle;
      }
    },
    setDefaultTitle() {
      const isIncludesGroupClass = () =>
        this.$store.getters["bill/orderItems"]
          .filter(orderItem => orderItem.selectType === "selected")
          .some(orderItem => orderItem.type === "default");

      if (isIncludesGroupClass()) {
        this.$store.dispatch(
          "bill/setBillTitle",
          this.$store.getters["bill/orderItems"].filter(
            orderItem =>
              orderItem.selectType === "selected" &&
              orderItem.type === "default"
          )[0].title
        );
      } else {
        const date = new Date().getDate();
        const YearMonth = moment(new Date())
          .subtract(date > 15 ? 0 : 1, "months")
          .format("YYYY MMMM");
        this.$store.dispatch("bill/setBillTitle", `${YearMonth} Tuition`);
      }
    },
    filterPayment(tutoringPayments) {
      if (this.closeSelectPaymentMethod) {
        return this.tutoringPayments.filter(
          payment => payment.title === "台幣轉帳帳號-1"
        );
      }
      return tutoringPayments;
    },
    handlePaymentSelectionChange(selectedPayment) {
      this.$store.dispatch("bill/setBillPaymentIndex", selectedPayment.index);
    },
    async fetchParents(searchText) {
      let queryParams = {
        keyword: searchText
      };

      const { students } = await userApi.searchParents(queryParams);
      this.searchStudents = students;
    },
    changeBillCurrency(currency) {
      this.$store.dispatch("bill/setBillCurrency", currency.id);
      this.updatePriceByCurrencyOfOrderItems(
        this.$store.getters["bill/orderItems"]
      );
      this.updatePriceByCurrencyOfLessonLogs(
        this.$store.getters["bill/lessonLogs"],
        this.$store.getters["bill/orderItems"]
      );
      this.updateBalanceInformation();
    },
    async removeParent() {
      try {
        await this.$confirm(
          this.$t("bill.removeParentAlarm"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }
      this.$store.dispatch("bill/initializeBill");
    },
    async selectedParent(parent, isCreateByOtherMethod) {
      if (!parent.parent_user_id) return;
      if (!isCreateByOtherMethod) {
        this.$store.dispatch("bill/setBillParent", parent.parent_user_id);
      }
      const { users } = await userApi.getStudentByParentId(
        parent.parent_user_id
      );
      this.studentsByParent = users.map(user => ({
        ...user,
        user_id: user.id,
        name: this.userMethods.displayName(user.first_name, user.last_name)
      }));

      if (users.length === 1 && !isCreateByOtherMethod) {
        const student = {
          name: this.userMethods.displayName(
            users[0].first_name,
            users[0].last_name
          ),
          user_id: users[0].id
        };
        await this.selectedStudent(student, true);
        await this.$store.dispatch("bill/setTempStudents", [student]);
      }
    },
    async selectedStudent(student, ignoreCheck) {
      if (!ignoreCheck && this.studentIsExist(student.user_id)) return false;
      await this.$store.dispatch("bill/addStudent", {
        id: student.user_id,
        name: student.name
      });
      await this.$store.dispatch("bill/getUnpaidListByStudentId", {
        studentId: student.user_id,
        doNotNeedToSelected: this.$route.name === "createBillWithOtherBill",
        defaultSelectedOrderId: this.$route.params.id,
        defaultSelectedLessonLogId: this.$route.params.lessonLogId
      });
      await this.updateCourseOriginalPriceOfEnrollment();
      this.updatePriceByCurrencyOfLessonLogs(
        this.$store.getters["bill/lessonLogs"],
        this.$store.getters["bill/orderItems"]
      );
      return true;
    },
    async updateCourseOriginalPriceOfEnrollment() {
      const enrollmentsWithOriginPricePromise = this.$store.getters[
        "bill/orderItems"
      ].map(async orderItem => {
        if (orderItem.type === "custom") {
          return {
            ...orderItem,
            originalPrice: 0,
            price: 0,
            priceCurrency: "TWD"
          };
        }
        if (Object.keys(orderItem).includes("edit")) return orderItem;
        return {
          ...orderItem,
          originalPrice: Number(
            orderItem.original_price || orderItem.originalPrice
          ),
          price: Number(orderItem.price),
          priceCurrency: orderItem.price_currency || orderItem.priceCurrency
        };
      });
      await Promise.all(enrollmentsWithOriginPricePromise).then(
        enrollmentsWithOriginPrice => {
          this.updatePriceByCurrencyOfOrderItems(enrollmentsWithOriginPrice);
        }
      );
    },
    async updatePriceOfEnrollment(orderItemId, enrollment) {
      try {
        await enrollmentApi.updateEnrollmentPrice(orderItemId, {
          original_price: enrollment.originalPrice,
          discount_info: enrollment.remark,
          price: Number(enrollment.price),
          price_currency: enrollment.priceCurrency
        });

        const orderItemWithCustomPrice = this.$store.getters[
          "bill/orderItems"
        ].map(orderItem => {
          if (orderItem.id !== orderItemId) return { ...orderItem };
          return { ...orderItem, ...enrollment };
        });
        this.updatePriceByCurrencyOfOrderItems(orderItemWithCustomPrice);
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
        return;
      }
    },
    updatePriceByCurrencyOfOrderItems(
      orderItems = this.$store.getters["bill/orderItems"]
    ) {
      const updatePriceByCurrencyOrderItems = orderItems.map(orderItem => {
        if (orderItem.type === "custom") {
          const billCurrency = this.$store.getters["bill/billCurrency"];
          return {
            ...orderItem,
            priceCurrency: billCurrency,
            priceByCurrency: orderItem.price
          };
        }

        return {
          ...orderItem,
          priceByCurrency: this.convertPriceByCurrency(
            orderItem.price,
            orderItem.priceCurrency,
            this.$store.getters["bill/billCurrency"]
          )
        };
      });
      this.$store.dispatch(
        "bill/setOrderItems",
        updatePriceByCurrencyOrderItems
      );
    },
    updatePriceByCurrencyOfLessonLogs(lessonLogs, orderItems) {
      const updatePriceByCurrencylessonLogs = lessonLogs.map(lessonLog => ({
        ...lessonLog,
        feeByCurrency: this.convertPriceByCurrency(
          lessonLog.fee,
          lessonLog.feeCurrency,
          this.$store.getters["bill/billCurrency"]
        )
      }));
      this.$store.dispatch(
        "bill/setLessonLogs",
        updatePriceByCurrencylessonLogs
      );

      orderItems.forEach(orderItem => {
        if (orderItem.type === "custom") {
          this.$store.dispatch(
            "bill/calculationOrderItemTotalByLessonLog",
            orderItem.id
          );
        }
      });
      this.updatePriceByCurrencyOfOrderItems();
    },
    async switchSelectType(type, switchStatus) {
      if (type === "orderItem") {
        this.$store.dispatch("bill/switchOrderItemSelectType", {
          orderItems: this.$store.getters["bill/orderItems"],
          ...switchStatus
        });
      } else if (type === "lessonLog") {
        this.$store.dispatch("bill/switchLessonLogSelectType", {
          lessonLogs: this.$store.getters["bill/lessonLogs"],
          ...switchStatus
        });
      }
      this.setDefaultTitle();
      this.updatePriceByCurrencyOfLessonLogs(
        this.$store.getters["bill/lessonLogs"],
        this.$store.getters["bill/orderItems"]
      );
    },
    async addOtherItem(orderItem) {
      this.$store.dispatch("bill/addOtherItem", orderItem);
    },
    async setOtherItem(orderIndex, value) {
      const orderItems = this.$store.getters["bill/orderItems"];
      const newOrderItems = orderItems.map(orderItem => ({ ...orderItem }));
      newOrderItems[orderIndex] = {
        ...newOrderItems[orderIndex],
        ...value
      };
      this.$store.dispatch("bill/setOrderItems", newOrderItems);
      this.updatePriceByCurrencyOfOrderItems(
        this.$store.getters["bill/orderItems"]
      );
    },
    async removeOtherOrderItem(orderIndex) {
      const orderItems = this.$store.getters["bill/orderItems"];
      const newOrderItems = orderItems.filter(
        (orderItem, index) => index !== orderIndex
      );
      this.$store.dispatch("bill/setOrderItems", newOrderItems);
      this.updatePriceByCurrencyOfOrderItems(
        this.$store.getters["bill/orderItems"]
      );
    },
    removeStudent(student, index) {
      if (this.$store.getters["bill/students"].length === 1) {
        this.$message({
          showClose: true,
          message: this.$t("bill.message.theStudentCanNotRemove"),
          type: "warning"
        });
        return;
      }
      this.$store.dispatch("bill/removeStudent", {
        targetIndex: index,
        student
      });
    },
    checkAllTempStudent() {
      this.$store.getters["bill/students"].forEach((student, index) => {
        this.$store.dispatch("bill/removeStudent", {
          targetIndex: index,
          student
        });
      });

      const selectedStudentPromises = this.studentsByParent.map(async student =>
        this.selectedStudent(student, true)
      );

      this.$store.dispatch(
        "bill/setTempStudents",
        this.studentsByParent.map(student => ({
          edit: false,
          user_id: student.id,
          name: this.userMethods.displayName(
            student.first_name,
            student.last_name
          )
        }))
      );
      Promise.all(selectedStudentPromises);
    },
    checkTempStudent(targetIndex) {
      const student = this.$store.getters["bill/tempStudents"][targetIndex];
      const isNewSelectedStudent = () => {
        const currentSelectedStudents = this.$store.getters["bill/students"];
        return !currentSelectedStudents.some(
          selectedStudent => selectedStudent.id === student.user_id
        );
      };

      if (this.studentIsExist(student.user_id)) return;
      if (isNewSelectedStudent()) {
        this.selectedStudent(
          this.$store.getters["bill/tempStudents"][targetIndex]
        );
      }
      this.$store.dispatch("bill/setTempStudent", {
        index: targetIndex,
        newStudent: { edit: false }
      });
    },
    studentIsExist(studentId) {
      const target = this.$store.getters["bill/tempStudents"].find(
        student => student.user_id === studentId && !student.edit
      );
      if (target) {
        this.$message.warning(this.$t("bill.message.theStudentAlreadyChooice"));
      }
      return Boolean(target);
    },
    enterToNextStep(nextStep) {
      const notYetFinishSave = [
        ...this.$store.getters["bill/orderItems"],
        ...this.$store.getters["bill/tempStudents"]
      ].some(orderItem => orderItem.edit);

      if (notYetFinishSave) {
        window.document.documentElement.scrollTop = 0;
        this.$message.error(this.$t("message.pleaseSaveEditingItemFirst"));
        return;
      }
      this.nextStep = nextStep;
    },
    changePaymentIndex(paymentIndex) {
      const isPayByBalance = paymentIndex === 10;
      if (isPayByBalance && !this.isBalanceEnough) {
        this.$message.error(this.$t("bill.message.balanceIsNotEnough"));
        return;
      }
      this.$store.dispatch("bill/setBillPaymentIndex", paymentIndex);
    },
    updateBalanceInformation() {
      this.tutoringPayments = this.tutoringPayments.map(tutoringPayment => {
        const BLANCE = 10;
        let title = tutoringPayment.title;
        if (tutoringPayment.index === BLANCE) {
          const { balance, balanceCurrency } = this.$store.getters[
            "bill/billParent"
          ];
          title = `${this.$t("bill.form.balance")}${this.addCurrencySymbol(
            this.convertPriceByCurrency(
              balance,
              balanceCurrency,
              this.$store.getters["bill/billCurrency"]
            ),
            this.$store.getters["bill/billCurrency"]
          )}`;
        }
        return { ...tutoringPayment, title };
      });
    }
  }
};
</script>

<style scoped>
.rowsGap {
  margin: 20px 0px;
}

.closeSelectPaymentMethodBtns {
  margin: 20px 0px;
}

.paymentInfo {
  margin: 4px;
  padding: 9.5px;
  background: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
