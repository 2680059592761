var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body container"},[_c('Breadcrumb',[_c('template',{slot:"lastPage"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],2),_c('h1',{staticClass:"text-center mt-5 mb-3"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]),_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_c('span',[_vm._v(_vm._s(_vm.$t("bill.form.billName")))])]),_c('el-col',{attrs:{"span":21}},[_c('el-input',{attrs:{"value":_vm.$store.getters['bill/billTitle']},on:{"input":value => {
            _vm.$store.dispatch('bill/setBillTitle', value);
          }}})],1)],1),_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_vm._v("繳費期限：")]),_c('el-col',{attrs:{"span":21}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"value":_vm.$store.getters['bill/billReceivableDate'],"type":"date","value-format":"yyyy-MM-dd"},on:{"input":value => {
            _vm.$store.commit('bill/setBillReceivableDate', value);
          }}})],1)],1),_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_vm._v(_vm._s(_vm.$t("bill.form.currency")))]),_c('el-col',{attrs:{"span":6}},[_c('CurrencySelector',{attrs:{"value":_vm.$store.getters['bill/billCurrency']},on:{"change":_vm.changeBillCurrency}})],1)],1),_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_vm._v("Notes")]),_c('el-col',{attrs:{"span":21}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"textarea","rows":2,"value":_vm.$store.getters['bill/billNotes']},on:{"input":value => {
            _vm.$store.dispatch('bill/setBillNotes', value);
          }}})],1)],1),_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_vm._v(_vm._s(_vm.$t("bill.form.parent")))]),(!_vm.$store.getters['bill/billParent'].name)?_c('el-col',{attrs:{"span":6}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value":_vm.$store.getters['bill/billParent'].id,"placeholder":_vm.$t('bill.form.searchParent'),"clearable":"","filterable":"","remote":"","remote-method":_vm.fetchParents},on:{"change":_vm.selectedParent}},_vm._l((_vm.searchStudents),function(student){return _c('el-option',{key:`${student.parent_user_id}${student.student_user_id}`,attrs:{"label":`${_vm.userMethods.displayName(
              student.parent_user_first_name,
              student.parent_user_last_name
            )}/${_vm.userMethods.displayName(
              student.student_user_first_name,
              student.student_user_last_name
            )}`,"value":student}})}),1)],1):_c('el-col',{attrs:{"span":6}},[_c('router-link',{attrs:{"to":{
          name: 'ProfileForAdmin',
          params: { id: _vm.$store.getters['bill/billParent'].id }
        }}},[_vm._v(" "+_vm._s(_vm.$store.getters["bill/billParent"].name)+" ")]),_c('span',{on:{"click":_vm.removeParent}},[_c('i',{staticClass:"fas fa-edit action-icon"})])],1)],1),(_vm.$store.getters['bill/billParent'].name)?_c('div',[_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_vm._v(" "+_vm._s(_vm.$t("bill.form.student"))+" ")]),_c('el-col',{attrs:{"span":16}},_vm._l((_vm.$store.getters['bill/tempStudents']),function(student,index){return _c('StudentForm',{key:student.id,attrs:{"student":student,"students":_vm.studentsByParent},on:{"setStudent":newStudent => {
              _vm.$store.dispatch('bill/setTempStudent', { index, newStudent });
            },"checkTempStudent":() => {
              _vm.checkTempStudent(index);
            },"editTempStudent":() => {
              _vm.$store.dispatch('bill/setTempStudent', {
                index,
                newStudent: { edit: true }
              });
            },"removeStudent":function($event){return _vm.removeStudent(student, index)}}})}),1)],1),_c('el-row',{staticStyle:{"margin-top":"-20px"},attrs:{"gutter":20}},[_c('el-col',{staticStyle:{"opacity":"0"},attrs:{"span":3}},[_vm._v("Fill block")]),_c('el-col',{attrs:{"span":6}},[(_vm.studentsByParent.length > 1)?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.$store.dispatch('bill/addTempStudent')}}},[_vm._v(" "+_vm._s(_vm.$t("bill.form.addStudent"))+" ")]):_vm._e(),(_vm.studentsByParent.length > 1)?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.checkAllTempStudent}},[_vm._v(" 增加所有的學生 ")]):_vm._e()],1)],1),_c('hr',{staticClass:"separate-line"}),_c('el-row',{staticClass:"rowsGap"},[_c('OrderItems',{attrs:{"editor":true,"isBillMode":true,"students":_vm.studentsByParent,"orderItems":_vm.$store.getters['bill/orderItems']},on:{"updatePriceOfEnrollment":_vm.updatePriceOfEnrollment,"switchOrderItemSelectType":switchStatus => {
            _vm.switchSelectType('orderItem', switchStatus);
          },"setBillTotalPrice":billTotalPrice => {
            _vm.$store.dispatch('bill/setBillTotalPrice', billTotalPrice);
          },"addOtherItem":_vm.addOtherItem,"setOtherItem":_vm.setOtherItem,"removeOtherOrderItem":_vm.removeOtherOrderItem}})],1),(_vm.$store.getters['bill/lessonLogs'].length > 0 && _vm.setLessonLogs(_vm.$store.getters['bill/lessonLogs']) && _vm.setLessonLogs(_vm.$store.getters['bill/lessonLogs']).length > 0)?_c('el-row',{staticClass:"rowsGap"},[_c('hr',{staticClass:"separate-line"}),_c('LessonLogs',{attrs:{"lessonLogs":_vm.setLessonLogs(_vm.$store.getters['bill/lessonLogs']),"billCurrency":_vm.$store.getters['bill/billCurrency']},on:{"switchLessonLogSelectType":switchStatus => {
            _vm.switchSelectType('lessonLog', switchStatus);
          }}})],1):_vm._e(),(_vm.isExistSelectedOrderItem)?[_c('hr',{staticClass:"separate-line"}),_c('el-row',{staticClass:"rowsGap"},[_c('h2',[_vm._v(_vm._s(_vm.$t("bill.form.paymentType")))]),_c('el-row',{staticClass:"rowsGap",attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":3}},[_vm._v(_vm._s(_vm.$t("bill.form.pleaseChoosePaymentType")))]),_c('el-col',{attrs:{"span":21}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value":_vm.$store.getters['bill/billPaymentIndex']},on:{"change":_vm.changePaymentIndex}},_vm._l((_vm.tutoringPayments),function(tutoringPayment){return _c('el-option',{key:tutoringPayment.index,attrs:{"label":_vm.addNoteToPaymentTitle(tutoringPayment.title),"value":tutoringPayment.index}})}),1)],1)],1),_c('el-row',{staticClass:"rowsGap",attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":3}},[_vm._v(_vm._s(_vm.$t("bill.form.payment")))]),_c('el-col',{attrs:{"span":21}},[(_vm.isPayByBalance)?_c('div',[_c('el-row',[_vm._v(" "+_vm._s(_vm.$t("bill.form.amount"))+" "+_vm._s(_vm.addCurrencySymbol( _vm.$store.getters["bill/billTotalPrice"], _vm.$store.getters["bill/billCurrency"] ))+" ")]),_c('el-row',[_vm._v(" "+_vm._s(_vm.$t("bill.form.balance"))+" "+_vm._s(_vm.$store.getters["bill/paidBillTotalByBalanceLeft"])+" ")])],1):_vm._e(),(_vm.isPayByOther)?_c('div',[_c('el-input',{attrs:{"type":"textarea","value":_vm.$store.getters['bill/paymentInfoRemark']},on:{"input":paymentInfoRemark =>
                    _vm.$store.commit(
                      'bill/setPaymentInfoRemark',
                      paymentInfoRemark
                    )}})],1):_c('div',{staticClass:"paymentInfo"},[(_vm.selectedPayment.account_name)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("bill.accountName"))+_vm._s(_vm.selectedPayment.account_name)+" ")]):_vm._e(),(_vm.selectedPayment.bank)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("bill.bank"))+_vm._s(_vm.selectedPayment.bank)+" ")]):_vm._e(),(_vm.selectedPayment.account_number)?_c('div',[(_vm.isMobilePayment)?_c('span',[_vm._v(_vm._s(_vm.$t("bill.account")))]):_c('span',[_vm._v(_vm._s(_vm.$t("bill.accountNumber")))]),_vm._v(_vm._s(_vm.selectedPayment.account_number)+" ")]):_vm._e(),(_vm.selectedPayment.other_info)?_c('div',[_c('el-image',{staticClass:"mt-2",staticStyle:{"width":"100px"},attrs:{"src":_vm.selectedPayment.other_info,"preview-src-list":[_vm.selectedPayment.other_info]}})],1):_vm._e()])])],1)],1),(!_vm.isPayByBalance)?_c('el-row',{staticClass:"rowsGap",attrs:{"type":"flex","align":"middle","justify":"center"}},[_c('span',[_vm._v(_vm._s(_vm.$t("bill.form.didTheParentsPay")))]),_c('el-button',{attrs:{"type":_vm.nextStep === 'sendMail' ? 'primary' : ''},on:{"click":function($event){return _vm.enterToNextStep('sendMail')}}},[_vm._v(" "+_vm._s(_vm.$t("bill.form.noSendToParents"))+" ")]),_c('el-button',{attrs:{"type":_vm.nextStep === 'markPaid' ? 'primary' : ''},on:{"click":function($event){return _vm.enterToNextStep('markPaid')}}},[_vm._v(" "+_vm._s(_vm.$t("bill.form.yseMarkPaid"))+" ")])],1):_vm._e(),_c('el-row',{staticClass:"rowsGap",attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":24}},[(_vm.nextStep === 'sendMail')?_c('SendMail',{attrs:{"students":_vm.$store.getters['bill/students'],"tutoringPayments":_vm.tutoringPayments,"parentId":_vm.$store.getters['bill/billParent'].id}}):_vm._e(),(_vm.nextStep === 'markPaid')?_c('MarkPaid'):_vm._e()],1)],1)]:_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }