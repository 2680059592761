<template>
  <el-row>
    <el-col :span="8" style="padding-left: 0px">
      <div v-if="student.edit">
        <StudentSelector
          :value="student.user_id"
          :students="students"
          @change="
            ({ user_id, name }) => {
              $emit('setStudent', { user_id, name });
            }
          "
        />
      </div>
      <div v-else class="studentGap">
        <el-col :span="23">
          <router-link
            :to="{
              name: 'ProfileForAdmin',
              params: { id: student.user_id }
            }"
          >
            {{ `${student.index}. ${student.name}` }}
          </router-link>
        </el-col>
      </div>
    </el-col>
    <el-col :span="1">
      <el-tooltip
        v-if="student.edit"
        :value="true"
        :content="$t('message.remeberSave')"
        placement="top"
        popper-class="tooltipColor"
      >
        <span key="check" @click="$emit('checkTempStudent')">
          <i class="fas fa-check action-icon" />
        </span>
      </el-tooltip>
      <span key="edit" v-else @click="$emit('editTempStudent')">
        <i class="fas fa-edit action-icon" />
      </span>
    </el-col>
    <el-col :span="1">
      <span @click="$emit('removeStudent')">
        <i class="el-icon-delete warning-icon" />
      </span>
    </el-col>
  </el-row>
</template>

<script>
import { StudentSelector } from "@/components/selector";
import "@/assets/scss/style.scss";

export default {
  props: ["student", "students"],
  components: { StudentSelector }
};
</script>

<style scoped>
.studentGap {
  margin: 0px 0px 8px 0px;
}

.el-row {
  margin: 0px 0px 8px 0px;
}
</style>
